@font-face {
  font-family: "Akkurat";
  src: url("typography/Akkurat-Normal.eot");
  src:
  url("typography/Akkurat-Normal.ttf") format('truetype'),
  url("typography/Akkurat-Normal.woff") format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Akkurat";
  src: url("typography/Akkurat-Bold.eot");
  src:
  url("typography/Akkurat-Bold.ttf") format('truetype'),
  url("typography/Akkurat-Bold.woff") format('woff');
  font-weight: bold;
  font-style: bold;
}

@font-face {
  font-family: "Akkurat";
  src: url("typography/Akkurat-Normal-Italic.eot");
  src:
  url("typography/Akkurat-Normal-Italic.ttf") format('truetype'),
  url("typography/Akkurat-Normal-Italic.woff") format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Akkurat";
  src: url("typography/Akkurat-Bold-Italic.eot");
  src:
  url("typography/Akkurat-Bold-Italic.ttf") format('truetype'),
  url("typography/Akkurat-Bold-Italic.woff") format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Georgia Pro";
  src: url("typography/GeorgiaPro-Semibold.eot");
  src:
  url("typography/GeorgiaPro-Semibold.ttf") format('truetype'),
  url("typography/GeorgiaPro-Semibold.woff") format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Georgia Pro";
  src: url("typography/GeorgiaPro-BoldItalic.eot");
  src:
  url("typography/GeorgiaPro-BoldItalic.ttf") format('truetype'),
  url("typography/GeorgiaPro-BoldItalic.woff") format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik';
  src: url('typography/Graphik-Bold.woff2') format('woff2'),
      url('typography/Graphik-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik';
  src: url('typography/Graphik-Semibold.eot');
  src: 
  url('typography/Graphik-Semibold.woff2') format('woff2'),
  url('typography/Graphik-Semibold.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Tiempos Text";
  src: url("typography/tiempos-text-web-regular.eot");
  src:
  url("typography/tiempos-text-web-regular.woff") format('woff'),
  url("typography/tiempos-text-web-regular.woff2") format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Tiempos Headline";
  src: url("typography/tiempos-headline-web-medium.eot");
  src:
  url("typography/tiempos-headline-web-medium.woff") format('woff'),
  url("typography/tiempos-headline-web-medium.woff2") format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Tiempos Headline";
  src: url("typography/tiempos-headline-web-semibold.eot");
  src:
  url("typography/tiempos-headline-web-semibold.woff") format('woff'),
  url("typography/tiempos-headline-web-semibold.woff2") format("woff2");
  font-weight: 700;
}

@font-face {
  font-family: "Georgia";
  src: 
  url("typography/Georgia.woff") format('woff'),
  url("typography/Georgia.woff2") format('woff2');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "Georgia";
  src:
  url("typography/Georgia\ Italic.woff") format('woff'),
  url("typography/Georgia\ Italic.woff2") format('woff2');
  font-style: italic;
  font-weight: normal;
}

@font-face {
  font-family: "Georgia";
  src:
  url("typography/Georgia\ Bold\ V2.woff") format('woff'),
  url("typography/Georgia\ Bold\ V2.woff2") format('woff2');
  font-style: normal;
  font-weight: bold;
}

@font-face {
  font-family: "Georgia";
  src:
  url("typography/Georgia\ Bold\ Italic\ V2.woff") format('woff'),
  url("typography/Georgia\ Bold\ Italic\ V2.woff2") format('woff2');
  font-style: italic;
  font-weight: bold;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 80px;
  overflow-x: hidden;
}

body {
  font-family: 'Akkurat';
  color: #222629;
}

body p {
  font-family: 'Akkurat', -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-weight: 500;
  margin-top: auto;
  margin-bottom: auto;
}

.App {
  text-align: center;
  color: #222629;
}

.smallred {
  Background: linear-gradient(#EB6E77, #D73440); 
  border-color: #BA212D !important;
  box-shadow: 0px 7px 22px #ff658980;

}

.smallgold {
  background: linear-gradient(#E3B286, #C69365);
  border-color: #BA8D66 !important;
  box-shadow: 0px 0px 15px #D19A6966;

}

.smallblue {
  background: linear-gradient(#66CAE3, #50B9D3);
  border-color: #36A7C4 !important;
  box-shadow: 0px 5px 15px #00CBFF54;
}

.smallyellow {
  background: linear-gradient(#FFF8A3, #FFEE1F);
  border-color: #E6CE4A !important;
  box-shadow: 0px 7px 22px #FFF84EB3;
  color: #222629 !important;
}

.smallblack {
  background: linear-gradient(#797979, #192224);
  box-shadow: 0px 7px 22px #A0A0A0B3;
  border-color: #222629 !important;
  color: #FFF578 !important;
}

#avinew-overlay-circle {
  position: absolute;
  top: 20%;
  left: 6%;
}

#avinew-overlay-text-why {
  position: absolute;
  top: 40%;
  left: 13%;
}

#avinew-overlay-x {
  position: absolute;
  top: 46%;
  left: 10%;
}

#avinew-overlay-text-trust {
  position: absolute;
  top: 60%;
  right: 10%;
}

#avinew-overlay-square {
  position: absolute;
  top: 80%;
  left: 32%;
}

#avinew-overlay-text-too-soon {
  position: absolute;
  top: 92%;
  left: 36%;
}

#avinew-markup {
  position: absolute;
  top: 25%;
}

.ux-auditing {
  color: #0288B1;
  text-decoration: underline;
}

.ux-auditing:hover {
  cursor: help;
}

.ux-auditing:hover:after {
  font-size: 16px;
  line-height: 21px;
  content: 'A User Experience Audit (UX Audit) is a way to pinpoint less-than-perfect areas of a digital product, revealing which parts of a site or app are causing headaches for users and stymieing conversions.';
  color: #222629;
  padding: 17px;
  background-color: #FFF;
  position: absolute;
  z-index: 2;
  width: 408px;
  max-width: 84%;
  border-radius: 10px;
  box-shadow: 0px 0px 0px 2px #D2DFE8, 0px 0px 40px 3px #57677240;
  text-align: left;
}

.nav-menu {
  position: absolute;
  right: 0px;
  top: 0px;
  background-color: #FFF;
  padding: 40px 32px 24px 42px;
  box-shadow: 0px 3px 9px #00000029;
  width: 233px;
  z-index: 2;
}

.nav-menu button {
  width: 100%;
  text-align: right;
  margin-bottom: 22.5px;
}

.nav-menu ul {
  list-style-type: none;
  padding: 0px;
}

.nav-menu li {
  padding-bottom: 20px;
}

.nav-menu a {
  text-decoration: none;
  color: #A7B6C1;
  font-family: 'Tiempos Headline', Georgia, "Georgia", 'Times New Roman', Times, serif;
  font-weight: 500;
  font-size: 19px;
}

.nav-menu a:hover {
  color: #42575F;
  font-weight: bold;
  text-decoration: none;
}


div.main-nav {
  margin: auto 25px auto auto;
  display: flex;
}

.main-menu {
  margin: auto;
}

.main-menu a {
  padding-right: 42px;
  font-family: 'Akkurat';
  font-size: 18px;
  color: #A7B6C1;
  text-decoration: none;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.main-menu a::after {
  content: attr(data-text);
  height: 0;
  visibility: hidden;
  overflow: hidden;
  user-select: none;
  pointer-events: none;
  font-weight: bold;
  width: fit-content;
}

.main-menu a:hover {
  color: #42575F;
  font-weight: bold;
  text-decoration: none;
}

.menu-button {
  border: none;
  background-color: #FFF;
  vertical-align: middle;
  white-space: nowrap;
}

.floating-nav {
  position: sticky;
  top: 20%;
  right: 100%;
}

.nav-wrapper {
  text-align: right;
  float: left;
  margin-left: 3.5%;
  margin-top: 104px;
}

#hero.jumbotron {
  margin-bottom: 5.5rem;
}

#hero p {
  font-family: "Akkurat";
  font-size: 27px;
  color: #222629;
  width: 82%;
  letter-spacing: -0.54px;
}

.separator {
  color: #36A7C4;
  margin-top: 12px;
  margin-bottom: 21px;
}

.floating-nav .text-logo {
  padding: 0 0 4px 0;
  border: none;
  margin-right: 0px;
}

.floating-nav .tagline {
  font-size: 15px;
  padding-bottom: 37px;
}

.floating-nav ul {
  list-style-type: none;
  line-height: 36px;
}

.floating-nav nav a {
  text-decoration: none;
  color: #A7B6C1;
  font-family: 'Akkurat', Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 19px;
  line-height: 36px;
}

.floating-nav nav a:hover {
  color: #42575F;
  font-weight: 700;
  text-decoration: none;
}

.floating-nav nav .is-current a {
  color: #42575F;
  font-weight: 700;
  text-decoration: none;
  padding-right: 8px;
  border-right: 1px solid #00CCFF;
  margin-right: -8px;
}


.refresh {
  border: none;
  background: none;
}

.menu-button:focus {
  outline: none;
  border: none;
}

.contact-button:focus {
  outline: none;
  border: none;
}

.contact-button:hover {
  text-decoration: none;
  color: #FFF;
}

.contact-button:active {
  text-decoration: none;
  color: #FFF;
}

.contact-button:visited {
  text-decoration: none;
  color: #FFF;
}

.brand {
  display: flex;
  flex-wrap: wrap;
  margin: auto auto auto 55px;
}

.text-logo {
  padding-right: 15px;
}


.tagline {
  color: #8294A0;
  font-family: 'Akkurat';
  font-size: 19px;
}


.menu-label {
  display: none;
  color: #A7B6C1;
  font-family: "Akkurat";
  font-size: 16px;
}

#footer {
  background-position: top center;
  background-size: 2831.16px;
  padding-top: 294px;

}

#hero img.hero-image {
  max-width: 1080px;
  margin-left: 50%;
  transform: translateX(-50%);
}

.satellite {
  margin-top: 86px;
  margin-bottom: 7px;
}

.headline-marker {
  margin-bottom: 72px;
}

.tweaked-here {
  margin-top: 42px;
  margin-bottom: 87px;
}

.pointing-arrow-right {
  margin-top: -34px;
  margin-right: 17px;
}

.pointing-arrow-left {
  margin-top: -32px;
  margin-left: 17px;
}

.description {
  font-family: 'Akkurat', Helvetica, Arial, sans-serif;
  font-size: 24px;
}

.case-study p {
  color: #47626C;
  letter-spacing: -0.7px;
  margin-top: 57px;
  font-size: 24px;
  line-height: 35px;
}

.description {
  font-size: 22px;
  letter-spacing: -0.58px;

}

.how-it-works h3 {
  font-family: 'Akkurat', Helvetica, Arial, sans-serif;
  font-weight: bold;
}

.georgia {
  font-family: Georgia, "Georgia", 'Times New Roman', Times, serif;
}

.georgia-pro {
  font-family: 'Georgia Pro', 'Tiempos Headline', Georgia, "Georgia", 'Times New Roman', Times, serif;
}

.tiempos-semi {
  font-family: 'Tiempos Headline', Georgia, "Georgia", 'Times New Roman', Times, serif;
  font-weight: 700;
}

.tiempos-med {
  font-family: 'Tiempos Headline', Georgia, "Georgia", 'Times New Roman', Times, serif;
  font-weight: 500;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

#hero {
  color: #222629;
  text-align: center;
}

img {
  max-width: 100%;
  overflow: visible;
  height: auto;
}

.nav-logo {
  padding: 1.5rem 1rem;
}

.highlighter {
  background-color: #7DD8EF80;
  box-shadow: 0px 0px 0px #7DD8EF80;
  padding-top: 6px;
  padding-bottom: 4px;
}

.highlighter-yellow {
  background-color: #FFF78E;
  padding-top: 6px;
  padding-bottom: 4px;
}

.navbar {
  width: 100%;
}

.navbar-toggler {
  float: right;
}

.col {
  vertical-align: middle;
}

#hero h1 {
  font-family: 'Graphik';
  font-weight: bold;
  font-size: 66px;
  line-height: 80px;
  margin-bottom: 46.8px;
  margin-top: 36px;
}

h2 {
  font-family: 'Graphik', Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 38px;
}

#intro .description {
  font-size: 24px;
  line-height: 35px;
}

#examples h2 {
  margin-bottom: 16px;
  font-size: 50px;
  line-height: 55px;
}

.interlude {
  text-align: center;
  border-top: 1px solid #50B9D3;
  border-bottom: 1px solid #50B9D3;
  background-color: #E8F7FA66;
  color: #222629;
  margin-top: 145px;
  margin-bottom: 145px;
  padding-top: 30px;
  padding-bottom: 30px;
  font-size: 28px;
}

#contact .interlude {
  border: none;
  background-color: #FFF;
  margin-top: 58px;
  margin-bottom: 46px;
  padding-top: 0px;
  padding-bottom: 0px;
  font-size: 38px;
  line-height: 53px;
}

.how-it-works {
  background-color: #E8F7FA;
  margin-top: 108px;
}


.how-it-title {
  margin-top: 50px;
}

.bullet {
  margin-bottom: 18px;
  padding-left: 8px;
  box-shadow: 0px 0px 0px 1px #FFF;
  width: fit-content;
}

.bullet h3 {
  font-family: 'Graphik';
  font-size: 23px;
  font-weight: bold;
}

.case-study {
  background: linear-gradient(#FFF, #F4F7F8);
  border-bottom: 1px solid #D2DFE8;
}

#fireside {
  border-top: 1px solid #D2DFE8;
}

p.how-it {
  width: 91%;
  font-family: 'Tiempos Text', 'Times New Roman', Times, serif;
  font-size: 19px;
  line-height: 32px;
}

p.how-it .highlighter {
  background-color: #FFF4A0;
}

img.overflowing {
  box-shadow: 0px 3px 40px #57677240;
  border-radius: 42px;
}

.glowing-yellow {
  box-shadow: 0px 0px 0px 4px #fff, 0px 0px 0px 10px #FFF78E, 0px 3px 40px #FFEE1F80 !important;
}

.purplish-blue {
  color: #212FDD;
}

.greenish-blue {
  color: #026EB1;
}

.comparison-text {
  display: block;
  margin: 0 auto 24.6px auto;
}

#comparisons {
  margin-top: 124px;
  margin-bottom: 57px;
  border-top: 1px solid #D2DFE8;
}

.comparisons h2 {
  margin-bottom: 42px;
}

.clients {
  margin-top: -57px;
}

.roi {
  margin-top: 41px;
  margin-bottom: 82px;
  z-index: 1;
}

.roi h3 {
  font-family: 'Graphik';
  font-weight: bold;
  font-size: 31px;
  margin-bottom: 0px;
}

.comparison-title {
  font-family: Georgia, "Georgia", 'Times New Roman', Times, serif;
  font-size: 24px;
  font-weight: bold;
  font-style: italic;
  color: #36A7C4;
  margin-bottom: 15px;
}

.circled-avinew {
  display: inline-block;
}

.circled-n2uitive {
  display: inline-block;
}

.circled-fireside {
  display: inline-block;
}
.circled-avinew::before {
position: absolute;
content: '';
background: url('./img/circled-graphic@2x.png');
background-size: 110%;
background-position: center;
width: 83px;
height: 83px;
pointer-events: none;
margin-top: -24px;
margin-left: -12px;
z-index: -1;
}

.circled-n2uitive::before {
  position: absolute;
  content: '';
  background: url('./img/circled-graphic@2x.png');
  background-size: 100%;
  background-position: center;
  width: 84px;
  height: 85px;
  pointer-events: none;
  margin-top: -24px;
  margin-left: -14px;
  z-index: -1;
}

.circled-fireside::before {
  position: absolute;
  content: '';
  background: url('./img/circled-graphic@2x.png');
  background-size: 100%;
  background-position: center;
  width: 76px;
  height: 76px;
  pointer-events: none;
  margin-top: -22px;
  margin-left: -15px;
  z-index: -1;
}

.fireside-roi {
  color: #FF6161;
  text-decoration: none;
}

.avinew-roi {
  color: #4D48BD;
  text-decoration: none;
}

.n2uitive-roi {
  color: #0E929F;
  text-decoration: none;
}

#beauty-interlude {
  margin-top: 72px;
  margin-bottom: 50px;
}

#testimonials {
  background-color: #FFF;
  padding-top: 72px;
  padding-bottom: 72px;
}

#testimonials h2 {
  margin-bottom: 45px;
}

div.testimonial {
  position: relative;
  background-color: #fff;
  color: #222629;
  font-size: 23px;
  text-align: left;
  border-radius: 10px;
  margin-top: 18px;
}

.testimonial p {
  font-family: 'Tiempos Text', Georgia, "Georgia", 'Times New Roman', Times, serif;
  font-size: 22px;
  position: relative;
  z-index: 4;
  padding: 32px 29px 33px 37px;
  border-radius: 8px;
}

.dave .testimonial p {
  background-color: #E7E9ED;
}

div.dave {
  border: 2px solid #E7E9ED;
  position: relative;
	background: #E7E9ED;
  width:auto;
  display:inline-block;
  z-index: 2;
}

div.dave-arrow {
  position: relative;
}

div.dave-arrow:after, div.dave-arrow:before {
	right: 100%;
	top: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

div.dave-arrow::before {
	border-color: rgba(12, 245, 167, 0);
	border-right-color: #E7E9ED;
	border-width: 52px;
  margin-top: -52px;
  left: -50px;
}

div.dave-arrow::after {
	border-color: rgba(40, 213, 127, 0);
	border-right-color: #E7E9ED;
	border-width: 50px;
  margin-top: -53px;
  left: -48px;
  z-index: 3;
}



.brooke.testimonial p {
  background-color: #FFF8A3;
}

div.brooke-arrow {
  position: relative;
}

div.brooke-arrow:after, div.brooke-arrow:before {
	right: 100%;
	top: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

div.brooke-arrow::before {
	border-color: rgba(12, 245, 167, 0);
	border-left-color: #FFF8A3;
	border-width: 52px;
  margin-top: -52px;
  right:-50px;
}

div.brooke-arrow::after {
	border-color: rgba(40, 213, 127, 0);
	border-left-color: #FFF8A3;
	border-width: 50px;
  margin-top: -53px;
  right:-48px;
}

div.phil {
  border: 1px solid #DDF4F8;
}

div.phil-arrow {
  position: relative;
}

div.phil-arrow:after, div.stone-arrow:before {
	right: 100%;
	top: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

div.phil-arrow::before {
	border-color: rgba(12, 245, 167, 0);
	border-right-color: #DDF4F8;
	border-width: 51px;
  margin-top: -54px;
  left: -50px;
}

div.phil-arrow::after {
	border-color: rgba(40, 213, 127, 0);
	border-right-color: #DDF4F8;
	border-width: 50px;
  margin-top: -54px;
  left: -50px;
}

.phil.testimonial p {
  background-color: #DDF4F8;
}

.shoutout {
  position: relative;
  margin-left: 83px;
  margin-top: 24px;
}

.shoutout div {
  margin-left: 14px;
}

.shoutout-right {
  position: relative;
  margin-right: 83px;
  margin-top: 24px;
  flex-wrap: wrap;
  flex-direction: row-reverse;
}

.shoutout-right div {
  margin-right: 14px;
}

.shoutout-right img {
  float: right;
}

.client-name {
  color: #36A7C4;
  font-family: 'Akkurat', Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 22px;
}

.client-title {
  color: #42575F;
  font-family: 'Akkurat', Helvetica, Arial, sans-serif;
  font-size: 19px;
}

img.brooke {
  position: relative;
  margin-top: -40px;
  margin-right: -35px;
}

img.stone {
  position: relative;
  margin-top: -40px;
  margin-left: -36px;
}

#faq {
  background-color: #F8F9FA;
  padding-top: 80px;
  padding-bottom: 112px;
}

#faq h2 {
  margin-bottom: 68px;
}

#faq {
  font-family: 'Akkurat', Hevletica, Arial, sans-serif;
}

.card-body {
  font-family: 'Tiempos Text', 'Times New Roman', Times, serif;
}

.card:first-of-type {
  border-radius: 10px;
}

.card:last-of-type {
  border-radius: 10px;
}

.card-header {
  background-color: #fff !important;
  font-size: 22px;
  padding: 0 35px !important;
  border-bottom: none !important;
  border-radius: 10px !important
}

.custom-toggle {
  display: flex;
  cursor: pointer;
  min-height: 92px;
}

.custom-toggle p {
  width: 100%;
  padding: 14px 7px 14px 0px
}

.custom-toggle img {
  float: right;
}

.card-header.active {
  color: #36A7C4;
  font-weight: bold;
}

.card-body {
  font-size: 19px;
  line-height: 32px;
  padding: 0 35px 35px 35px !important;
}

.contact-area {
  background-color: #FFF;
  padding-top: 72px;
}

.contact-form {
  margin: 110px auto 0;
  text-align: center;
  background-color: #FFF8A3;
  padding: 51px 80px 43.5px;
  border-radius: 22px;
  width: 813px;
  max-width: 90%;
}

.contact-form .col-md {
  padding: 0 23px;
}

.contact-boxes .col-md {
  padding-bottom: 49px;
  margin-bottom: 30px;
}

.contact-form h3 {
  font-family: 'Graphik';
  font-weight: bold;
  font-size: 38px;
  font-weight: 700;
}

.contact-boxes p{
  font-size: 20px;
  /*width: 80%;*/
}
.contact-boxes label {
  font-family: 'Akkurat';
  font-size: 15px;
  line-height: 17px;
  margin-top: 12px;
  margin-bottom: 6px;
}

.contact-boxes img {
  border-radius: 20px;
}

.contact-buttons {
  padding-top: 7px;
}

.contact-button {
  text-align: center;
  font-size: 16px;
  font-family: 'Graphik', Arial, sans-serif;
  font-weight: 500;
  color: #FFF;
  width: 80px;
  padding: 8px 22px 8px 22px;
  border: 1px solid;
  border-radius: 88px;
  margin-top: 13px;
  white-space: nowrap;
  width: auto;
}

.button-subtext {
  font-family: 'Akkurat', Arial, Helvetica, sans-serif;
  font-style: italic;
  color: #47626C;
  font-size: 18px;
  letter-spacing: -0.7px;
  margin-top: 20px;
}

.bs {
  font-weight: bold;
  text-decoration: underline;
}

.final-upsell {
  font-family: 'Akkurat', Arial, Helvetica, sans-serif;
  font-size: 14px;
  letter-spacing: -0.7px;
}

label {
  font-size: 15px;
  line-height: 17px;
  margin: 12px 0 6px 0;
  float: left;
}

p.success {
  color: #36A7C4;
  margin-bottom: 33px;
}

img.success {
  box-shadow: 0px 0px 7px #36A7C454;
}

@media (max-width: 767px) {
  .contact-form {
    padding: 42px;
  }
  #hero h1 {
    font-size: 55px;
    line-height: 77px;
  }

  p.description {
    margin-top: 25px !important;
    text-align: center !important;
  }

  .aftertext {
    margin-top: 57px;
  }

  .compare-arrow {
    display: none;
  }
}

.contact-boxes .col-md {
  padding: 26px 40px 25px 40px;
}

form.contact_form label input[type='checkbox']{margin-left:-18px; border-color: #A7B6C1; border-radius: 4px}

.contact-form textarea {
  width: 100%;
  height: 202px;
  border: 1px solid #A7B6C1;
  border-radius: 4px;
  resize: none;
}

.contact-form input {
  height: 44px;
  border: 1px solid #A7B6C1;
  border-radius: 4px;
}

input.contact-button {
  border-radius: 88px;
  padding: 8px 28px;
}

.jagged {
  padding-top: 63px;
  padding-bottom: 63px;
}

.comparison-title {
  margin-top: 21px;
}

#footer {
  font-size: 18px;
  margin-bottom: 44px;
  color: #8294A0;
}


@media (min-width: 1200px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
      max-width: 1080px !important;
  }
}

@media (max-width: 1536px) {
  .tagline {
    display: none;
  }
}
@media (min-width: 1536px) {
  .text-logo {
    border-right: 1px solid #D2DFE8;
    margin-right: 13px;
  }
}

@media (max-width: 1692px) {
  .floating-nav {
    display: none;
  }

  .header {
    position: fixed;
    top: 0;
    z-index: 10;
  }

  #hero {
    margin-top: 134px;
  }
}

@media (max-width: 1365px) {
  .main-menu {
    display: none;
  }
  
  .laptop-menu {
    margin-left: 42px;
  }
}

@media (min-width: 1366px) {
  .laptop-menu {
    display: none;
  }
}

@media (max-width: 795px) {
  .desktop {
    display: none !important;
  }

  .description {
    padding-left: 16px;
    padding-right: 16px; 
  }

  #intro h2 {
    padding-left: 16px;
  }

  #examples h2{
    margin-top: 16px;
  }

  p.how-it {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 23px;
    width: 100%;
  }

  .interlude .georgia {
    padding-left: 16px;
    padding-right: 16px;
  }

  .bullet {
    margin-top: 42px;
  }

  #testimonials .col {
    padding-left: 31px;
    padding-right: 31px;
  }

  #contact-flow {
    display: none !important;
  }

  .contact-boxes {
    padding-top: 49px;
  }

  .vertical-spacer {
    display: none;
  }
  
  div.menu-button {
    margin: auto 25px auto auto;
  }

  .brand {
    margin: auto auto auto 25px;
  }

  .contact-2 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }

  .notched-border {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .menu-label {
    display: inline !important;
  }

  div.main-nav {
    margin: auto 55px auto auto;
  }

  .contact-1 {
    border-right: 1px solid #D2DFE8;
    padding-left: 0px !important;
  }

  .contact-3 {
    border-left: 1px solid #D2DFE8;
    padding-right: 0px !important;
  }

  div.overflowing {
    padding: 0px;
    width: fit-content;
  }

  #comparisons {
    margin-bottom: 134px;
  }

  #testimonials {
    padding-top: 142px;
  }

  #beauty-interlude {
    margin-top: 135px;
  }

  .contact-area {
    padding-top: 92px;
  }

  .compare-arrow {
    padding-top: 69px;
  }

  .avinew.compare-arrow {
    padding: 69px 23px 0 23px !important;

  }

  .mobile {
    display: none !important;
  }

  .bullet {
    margin-top: 23px;
    margin-right: 8px;
  }
}

@media (min-width: 585px) { 
  .tagline {
  vertical-align: bottom;
  margin-top: auto;
  }

  #testimonials .col {
    padding: 0;
  }
}


@media (max-width: 575px) {
  .menu-button button {
    padding-bottom: 31px !important;
  }

  .main-nav .contact-button {
    display: none;
  }

  .ux-auditing:hover:after {
    left: 9%;
  }

  .contact-button.smallblack {
    padding: 16px 39px !important;
  }
}

@media (min-width: 576px) {
  .comparisons {
    padding: 0;
  }
  #howitworks {
    border-radius: 22px;
  }
}

.header {
  width: 100%;
  height: 134px;
  display: flex;
  background-color: #FFFFFFE6;
  backdrop-filter: blur(3.5px);
}

@media (min-width: 992px) {
  .explanation {
    margin-left: -6%;
  }
  p.how-it {
    width: 93%;
  }
}
